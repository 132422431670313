class PinSet {
    constructor() {
        
        this.pinEls = document.querySelectorAll(".c-pin");
        this.pins = Array.prototype.map.call(this.pinEls, this.initPin.bind(this));
    }

    initPin(item) {
        return new Pin(item, this.pinClickHandler.bind(this));
    }

    pinClickHandler() {
        this.pins.forEach((item) => item.close());
    }
}

class App {
    constructor() {
        this.moreBtn = document.querySelector(".js-more-btn");
        this.navToggleBtn = document.querySelector(".js-toggle-nav-btn");

        this.moreBtn.addEventListener("click", this.moreBtnClickHandler.bind(this));
        this.navToggleBtn.addEventListener("click", this.navToggleBtnClickHandler.bind(this));

        this.howToSlider = new HowToSlider();
        this.storyMap = new StoryMap();
        this.pinSet = new PinSet();

        //document.documentElement.classList.add("noscroll");
    }

    moreBtnClickHandler() {
        document.documentElement.classList.remove("noscroll");
        Velocity(document.documentElement, "scroll", { offset: window.innerHeight + "px", mobileHA: false });
    }

    navToggleBtnClickHandler() {
        document.documentElement.classList.remove("noscroll");
    }

    initMap() {
        this.storyMap.init();
    }
}
